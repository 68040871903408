.centered {
  transform: translate(-50%, -50%) !important
}

.mantine-Input-disabled {
  font-weight: 700;
  opacity: 1 !important;
}

.hoverable {
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hoverable:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.theme-transition {
  transition: background-color 0.3s ease, color 0.3s ease;
}
